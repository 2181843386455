import React, { createContext, useEffect, useState } from "react";
// import logo from "../../Assets/logoBlue.png";
import Step1 from "../../components/PlanSteps/Step1";
import Step2 from "../../components/PlanSteps/Step2";
import Step3 from "../../components/PlanSteps/Step3";
import Step4 from "../../components/PlanSteps/Step4";
import Step5 from "../../components/PlanSteps/Step5";
import Step6 from "../../components/PlanSteps/Step6";
import Step7 from "../../components/PlanSteps/Step7";
import Step8 from "../../components/PlanSteps/Step8";
import Step9 from "../../components/PlanSteps/Step9";
import Step10 from "../../components/PlanSteps/Step10";
import Step11 from "../../components/PlanSteps/Step11";
import Step12 from "../../components/PlanSteps/Step12";
import Step13 from "../../components/PlanSteps/Step13";
import Step14 from "../../components/PlanSteps/Step14";
import Step15 from "../../components/PlanSteps/Step15";
import Step16 from "../../components/PlanSteps/Step16";
import Step17 from "../../components/PlanSteps/Step17";
import Step18 from "../../components/PlanSteps/Step18";
import Step19 from "../../components/PlanSteps/Step19";
import Step20 from "../../components/PlanSteps/Step20";
import Step21 from "../../components/PlanSteps/Step21";
import Step22 from "../../components/PlanSteps/Step22";
import Step23 from "../../components/PlanSteps/Step23";
import Step24 from "../../components/PlanSteps/Step24";
import Step25 from "../../components/PlanSteps/Step25";
import Step26 from "../../components/PlanSteps/Step26";
import { generateStateData } from "../../Utils/smartPlanGenerate";
import Loader from "../../components/Loading/Loader";

export const SmartPlanContext = createContext();

const Steps = {
  1: Step1,
  2: Step2,
  3: Step3,
  4: Step4,
  5: Step5,
  6: Step6,
  7: Step7,
  8: Step8,
  9: Step9,
  10: Step10,
  11: Step11,
  12: Step12,
  13: Step13,
  14: Step14,
  15: Step15,
  16: Step16,
  17: Step17,
  18: Step18,
  19: Step19,
  20: Step20,
  21: Step21,
  22: Step22,
  23: Step23,
  24: Step24,
  25: Step25,
  26: Step26,
};

const SmartPlan = ({ data }) => {
  const [step, setStep] = useState(1);
  const [isUpdate, setIsUpdate] = useState(data ? true : false);
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState(null);
  const CurrentStep = Steps[step];
  const [laoding, setLoading] = useState(false);

  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState({
    gender: null,
    birthMonth: null,
    birthYear: null,
    retirementYear: null,
    // longevityAge: null,
  });
  const [step3Data, setStep3Data] = useState();
  const [step4Data, setStep4Data] = useState({
    companyName: null,
    incomeAmount: null,
    frequency: null,
    additionalIncome: null,
  });
  const [step5Data, setStep5Data] = useState({
    additionalIncomeType: null,
    businessType: null,
    industry: null,
    amount: null,
  });
  const [step6Data, setStep6Data] = useState({
    companyName: null,
    incomeAmount: null,
    frequency: null,
    additionalIncome: false,
    additionalIncomeType: null,
    businessType: null,
    industry: null,
    amount: null,
  });
  const [step7Data, setStep7Data] = useState(null);
  const [step8Data, setStep8Data] = useState([
    {
      name: null,
      age: null,
    },
  ]);

  const [step9Data, setStep9Data] = useState({
    custodialAccount: null,
    buildWealth: null,
  });
  const [step10Data, setStep10Data] = useState([
    {
      Institution: null,
      Amount: null,
    },
  ]);
  const [step11Data, setStep11Data] = useState(null);
  const [step12Data, setStep12Data] = useState(null);
  const [step13Data, setStep13Data] = useState({
    homeValue: null,
    loadBalance: null,
    monthlyPayment: null,
    interestRate: null,
  });
  const [step14Data, setStep14Data] = useState({
    rentAmount: null,
  });
  const [step15Data, setStep15Data] = useState(null);
  const [step16Data, setStep16Data] = useState([
    {
      accountType: null,
      currentBalance: null,
      instituteName: null,
      returnRate: null,
      type: null,
    },
  ]);
  const [step17Data, setStep17Data] = useState([
    {
      accountName: null,
    },
  ]);

  const [step18Data, setStep18Data] = useState([
    {
      assetType: null,
      assetName: null,
      assetValue: null,
      assetInsurrance: false,
    },
  ]);
  const [step19Data, setStep19Data] = useState(null);
  const [step20Data, setStep20Data] = useState({
    debt: [
      {
        debtType: null,
        instituteName: null,
        monthlyPayment: null,
        balance: null,
        rateOfDebt: null,
        otherDebtName: null,
      },
    ],
    debtCondition: null,
    guidance: null,
  });
  const [step21Data, setStep21Data] = useState({
    isCreditScore: null,
    creditScoreCount: null,
  });
  const [step22Data, setStep22Data] = useState({
    spouseIsCreditScore: null,
    spouseCreditScoreCount: null,
  });
  const [step23Data, setStep23Data] = useState(null);

  const [step24Data, setStep24Data] = useState({
    avgMonthlyExpense: null,
  });
  const [step25Data, setStep25Data] = useState({
    lastWill: null,
    livingTrust: null,
    healthCarePowerOfAttorney: null,
    financialPowerOfAttorney: null,
    documentsOrganized: null,
    identityTheftProtection: null,
    onTrackDebtFree: null,
    budgetingAndDebtPayoffTool: null,
    preparedToManageFinances: null,
    interestedInEducationAndServices: null,
    reportingRentPayments: null,
  });

  const [step26Data, setStep26Data] = useState({
    personalInfo: {
      retirementAge: null,
      dob: null,
    },
  });

  const contextData = {
    step1Data,
    setStep1Data,
    step2Data,
    setStep2Data,
    step3Data,
    setStep3Data,
    step4Data,
    setStep4Data,
    step5Data,
    setStep5Data,
    step6Data,
    setStep6Data,
    step7Data,
    setStep7Data,
    step8Data,
    setStep8Data,
    step9Data,
    setStep9Data,
    step10Data,
    setStep10Data,
    step11Data,
    setStep11Data,
    step12Data,
    setStep12Data,
    step13Data,
    setStep13Data,
    step14Data,
    setStep14Data,
    step15Data,
    setStep15Data,
    step16Data,
    setStep16Data,
    step17Data,
    setStep17Data,
    step18Data,
    setStep18Data,
    step19Data,
    setStep19Data,
    step20Data,
    setStep20Data,
    step21Data,
    setStep21Data,
    step22Data,
    setStep22Data,
    step23Data,
    setStep23Data,
    step24Data,
    setStep24Data,
    step25Data,
    setStep25Data,
    step26Data,
    setStep26Data,
  };

  function handleNextStep() {
    setDirection("next");
    if (step === 26) {
      console.log("No other step");
    } else {
      setStep(step + 1);
    }
  }

  function handleBackStep() {
    setDirection("back");
    if (step !== 1) {
      setStep(step - 1);
    } else {
      window.location.reload();
    }
  }

  function setCustomStepNumber(stepNumber, type = "next") {
    setStep(stepNumber);
  }

  useEffect(() => {
    setProgress(step * 5.88);
  }, [step]);

  useEffect(() => {
    if (data) {
      setLoading(true);
      generateStateData(
        setStep1Data,
        setStep2Data,
        setStep3Data,
        setStep4Data,
        setStep5Data,
        setStep6Data,
        setStep7Data,
        setStep8Data,
        setStep9Data,
        setStep10Data,
        setStep11Data,
        setStep12Data,
        setStep13Data,
        setStep14Data,
        setStep15Data,
        setStep16Data,
        setStep17Data,
        setStep18Data,
        setStep19Data,
        setStep20Data,
        setStep21Data,
        setStep22Data,
        setStep23Data,
        setStep24Data,
        setStep25Data,
        setStep26Data,
        data
      );
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, []);
  return laoding ? (
    <Loader />
  ) : (
    <div className="w-full">
      {/* navbar  */}
      <div className="flex justify-center gap-4 items-center py-3 px-12 ">
        <h6 className="capitalize satoshi-500 text-[--gray]">step {step}</h6>
      </div>
      {/* progress bar  */}
      {/* <div className="w-full h-[4px] bg-[--gray-light]">
        <div
          style={{ width: `${progress}%` }}
          className="h-full bg-[--primary] rounded-sm transition-all ease-in-out duration-500"
        ></div>
      </div> */}
      {/* steps  */}
      <SmartPlanContext.Provider value={contextData}>
        <CurrentStep
          direction={direction}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          customStep={setCustomStepNumber}
          isUpdate={isUpdate}
        />
      </SmartPlanContext.Provider>
    </div>
  );
};

export default SmartPlan;

//     "dontHaveAccounts": [
//     null,
//     null
// ],
