import { some } from "lodash";
import InputSmall from "../components/Input/InputSmall";
import InputWrapper from "../components/Wrapper/InputWrapper";

import { toast } from "react-toastify";
import moment from "moment";

export const renderInputs = (childrens, onchange) => {
  const inputs = [];

  for (let i = 0; i < childrens; i++) {
    inputs.push(
      <InputWrapper key={i}>
        <InputSmall
          id={`childName${i + 1}`}
          name={`childName${i + 1}`}
          type="text"
          label="Children Name"
          placeholder="John Doe"
          onChange={onchange}
        />
        <InputSmall
          id={`age${i + 1}`}
          name={`childAge${i + 1}`}
          type="number"
          label="Age"
          placeholder="Child Age"
          onChange={onchange}
        />
      </InputWrapper>
    );
  }

  return inputs;
};

export function successToast(message) {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function errorToast(message) {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function structureSmartPlanData(data, values) {
  let structureData = {
    userId: null,
    name: values.name,
    description: values.description,
    personalInformation: {},
    AssetsManagement: {
      Assets: [
        {
          Description: "",
          Balance: 0,
          anualContribution: "",
          employeeContribution: "",
        },
      ],
      SpouseSavings: [
        {
          Description: "",
          Balance: 0,
          anualContribution: "",
          employeeContribution: "",
        },
      ],
    },
    Debt: {
      debtInformation: [
        {
          debtType: "",
          description: "",
          balance: "",
          anualContribution: "",
        },
      ],
      OtherLiabilities: [""],
    },
    RiskTolerance: {
      self: "",
      spouse: "",
      lastUpdated: {
        description: "",
        will: false,
      },
      lastAmmended: {
        description: "",
        will: false,
      },
      savingForChildres: true,
      selfLifeInsurance: {
        Answer: false,
        howMuch: 0,
      },
      wifeLifeInsurance: {
        Answer: false,
        howMuch: 0,
      },
      questionsAndConcerns: "",
    },
    desiredRetirement: {
      ageToRetire: 0,
      acceptableAge: 0,
      spouseAgeToRetire: 0,
      spouseAcceptableAge: 0,
    },
    workInRetirement: {
      status: false,
      howManyYears: 0,
      expectedIncome: 0,
      spouseStatus: true,
      spouseHowManyYears: 0,
      spouseExpectedIncome: 0,
      desiredIncomeInRetirement: 0,
      acceptableAge: 0,
    },
    socialSecurityBenefits: {
      monthlyBenefits: "",
      spouseMonthlyBenefits: "",
      divorced: false,
      window: false,
    },
  };

  structureData.personalInformation = data.personalInformation;
  structureData.AssetsManagement.Assets = data.assets.yourAssets.map((item) => {
    return {
      Balance: parseFloat(item.Balance),
      Description: item.Description,
      anualContribution: parseInt(item.anualContribution),
      employeeContribution: parseInt(item.employeeContribution),
    };
  });
  structureData.AssetsManagement.SpouseSavings = data.assets.SpouseSavings.map(
    (item) => {
      return {
        Balance: parseFloat(item.Balance),
        Description: item.Description,
        anualContribution: parseInt(item.anualContribution),
        employeeContribution: parseInt(item.employeeContribution),
      };
    }
  );
  structureData.Debt.debtInformation = data.debt.debtInformation.map((item) => {
    return {
      anualContribution: parseInt(item.anualContribution),
      balance: parseInt(item.balance),
      debtType: item.debtType,
      description: item.description,
    };
  });
  let liablity = [];
  data.debt.OtherLiabilities.forEach((item) => {
    liablity.push(parseInt(item.text));
  });
  structureData.Debt.OtherLiabilities = liablity;
  structureData.RiskTolerance = {
    self: data.RiskTolerance.self,
    spouse: data.RiskTolerance.spouse,
    lastUpdated: {
      description: data.RiskTolerance.lastUpdated,
      will: data.RiskTolerance.will,
    },
    lastAmmended: {
      description: data.RiskTolerance.lastAmmended,
      will: data.RiskTolerance.will,
    },
    savingForChildres: data.RiskTolerance.savingForChildrens,
    selfLifeInsurance: {
      Answer: data.RiskTolerance.selfLifeInsurance,
      howMuch: data.RiskTolerance.selfAmount,
    },
    wifeLifeInsurance: {
      Answer: data.RiskTolerance.wifeLifeInsurance,
      howMuch: data.RiskTolerance.spouseInsuranceAmount,
    },
    questionsAndConcerns: data.RiskTolerance.questionsAndConcerns,
  };
  structureData.desiredRetirement = data.desiredRetirements;
  structureData.workInRetirement = {
    status: data.workInRetirement.status,
    howManyYears: parseInt(data.workInRetirement.howManyYears),
    expectedIncome: parseInt(data.workInRetirement.expectedIncome),
    spouseStatus: data.workInRetirement.spouseStatus,
    spouseHowManyYears: parseInt(data.workInRetirement.spouseHowManyYears),
    spouseExpectedIncome: parseInt(data.workInRetirement.spouseExpectedIncome),
    desiredIncomeInRetirement: parseInt(
      data.workInRetirement.desiredIncomeInRetirement
    ),
    acceptableAge: parseInt(data.workInRetirement.acceptableAge),
  };
  structureData.socialSecurityBenefits = data.socialSecurity;

  return structureData;
}

export function calculate(columnName, data) {
  let total = 0;
  data.forEach((row) => {
    let columnValue = row[columnName];
    if (!columnValue) {
      total = total;
    } else {
      total = total + parseInt(columnValue);
    }
  });
  return total;
}

export function structureDebtPlan(data) {
  let structureData = {
    monthlyBudget: data.monthlyBudget,
    planData: [],
  };
  data.data.map((item) => {
    structureData.planData.push({
      creditor: item.creditor,
      balance: item.balance,
      rate: item.rate,
      payment: item.payment,
      custom: item.custom,
    });
  });
  return structureData;
}

// export function checkIncomeExpense(income, expense, newEntry, duration) {
//   let totalExpense = 0;
//   if (duration === "Week") {
//     let totalNewEntry = newEntry * 4;
//     totalExpense = expense + totalNewEntry;
//   } else if (duration === "Yearly") {
//     let totalNewEntry = newEntry / 12;
//     totalExpense = expense + totalNewEntry;
//   } else {
//     totalExpense = expense + newEntry;
//   }
//   return income > totalExpense;
// }

export function returnTypeIndex(data, type) {
  return data.findIndex((item) => item.typeName === type);
}

export function findIndexOfMaxMonthsToPayOff(data) {
  let maxIndex = -1;
  let maxMonthsToPayOff = -Infinity;

  for (let i = 0; i < data.length; i++) {
    const monthsToPayOff = data[i].monthsToPayOff;
    if (monthsToPayOff > maxMonthsToPayOff) {
      maxMonthsToPayOff = monthsToPayOff;
      maxIndex = i;
    }
  }

  return maxIndex;
}

export function totalInterest(data) {
  let total = 0;
  data.forEach((item) => {
    total = total + parseFloat(item.interestpaid);
  });

  return total;
}

export function compareData(formData, originalData) {
  let changeFound = false;

  // Function to recursively compare nested objects
  function deepCompare(newData, original) {
    // Check if both arguments are objects
    if (typeof newData !== "object" || typeof original !== "object") {
      if (newData !== original) {
        changeFound = true;
        return;
      } else {
        return;
      }
    }

    // Get the keys of originalData
    const keys1 = Object.keys(newData);

    // Check if each key and value in originalData is the same as in formData
    for (let key of keys1) {
      // Skip comparison for the 'photo' key
      if (key === "photo") {
        continue;
      }

      // If newData doesn't have a key of originalData or the values are different, return false
      if (
        !original.hasOwnProperty(key) ||
        !deepCompare(newData[key], original[key])
      ) {
        changeFound = true;
        return;
      }
    }
  }

  // Compare the keys and values of formData with originalData
  if (!changeFound) {
    deepCompare(formData, originalData);
  } else {
    return changeFound;
  }
}
// {
//   month: "Feb-2024",
//   snowball: 3.13,
//   creditor1: 10,
//   creditor2: 20,
//   creditor3: 40,
//   creditor4: 660,
//   creditor5: 5020,
//   creditor6: 1200,
// }

export function formatScheduleData(data) {
  const structureData = [];
  data.forEach((item) => {
    let dataobject = {
      month: null,
      snowball: 0,
    };
    for (let key in item) {
      dataobject = { ...dataobject, month: key };
      dataobject = { ...dataobject, snowball: item[key].totalToAdd };
      item[key].creditors.forEach((creditor) => {
        let name = creditor.creditorName.split(" ");
        let keyName = name;
        // if(name.length >1){
        //   keyName = name[0]+"_"+name[1]
        // }
        let creditorObj = {
          [keyName]: creditor?.payment > 0 ? creditor.payment.toFixed(2) : "-",
        };

        dataobject = { ...dataobject, ...creditorObj };
      });
    }
    structureData.push(dataobject);
  });

  return structureData;
}

export function generatePercentageInfo(goalDone, totalWeeks) {
  const percentagePerWeek = parseInt(100 / totalWeeks);
  const progressWeek = goalDone / percentagePerWeek;
  const stringPercentage = progressWeek.toFixed(2).toString().split(".");
  const targetIndex = parseInt(stringPercentage[0]);
  const widthPercentage = stringPercentage[1] + "%";

  const generatedMarkup = [];

  for (let i = 0; i < totalWeeks; i++) {
    if (i < targetIndex) {
      generatedMarkup.push(
        <div
          key={i}
          className="min-w-[60px] flex-grow bg-[--gray-light] rounded-2xl"
        >
          <div className={`py-[5px] bg-[--primary] w-[100%] rounded-2xl`}></div>
        </div>
      );
    } else if (targetIndex === i) {
      generatedMarkup.push(
        <div
          key={i}
          className="min-w-[60px] flex-grow bg-[--gray-light] rounded-2xl"
        >
          <div
            style={{ width: widthPercentage }}
            className={`py-[5px] bg-[--primary]  rounded-2xl`}
          ></div>
        </div>
      );
    } else {
      generatedMarkup.push(
        <div
          key={i}
          className="min-w-[60px] flex-grow bg-[--gray-light] rounded-2xl"
        >
          <div className={`py-[5px] bg-[--primary] w-[0%] rounded-2xl`}></div>
        </div>
      );
    }
  }
  return generatedMarkup;
}

export function removeElementByIndex(array, targetIndex) {
  return array.filter((_, index) => index !== targetIndex);
}

export const calculateTotalIncome = (data) => {
  let total = 0;
  data.forEach((item) => {
    if (isNaN(item.amount)) {
      total = parseInt(total) + 0;
    } else {
      total = parseInt(total) + parseInt(item.amount);
    }
  });
  return total;
};

export const calculateTotalExpense = (data) => {
  let total = 0;
  data?.forEach((item) => {
    item?.data?.forEach((ele) => {
      if (isNaN(ele.amount)) {
        total = parseInt(total) + 0;
      } else {
        total = parseInt(total) + parseInt(ele.amount);
      }
    });
  });
  return total;
};

export const budgetDestructure = ({
  incomeData,
  expenseData,
  expenseTypeId,
}) => {
  let structuredIncome = incomeData.map((item) => {
    return {
      name: "Income",
      type: item?.budgetType,
      budgetPurpose: item?.name,
      amount: item?.amount,
      duration: item?.duration,
    };
  });
  let structuredExpense = [];
  expenseData.forEach((item) => {
    item.data.forEach((ele) => {
      structuredExpense.push({
        name: "EXPENSE",
        type: expenseTypeId,
        category: ele.categoryId,
        subCategory: item.subcategoryId,
        budgetPurpose: ele.purposeId,
        amount: ele.amount,
        duration: "Monthly",
      });
    });
  });
  return { structuredIncome, structuredExpense };
};

export const generateBudgetYear = () => {
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push({
      value: year,
      label: year,
    });
  }
  return years;
};

export const generateMonth = () => {
  return [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
};

export function getGoalCardText(data) {
  return data?.goalType === "Financial"
    ? `$ ${data?.savedAmount} amount saved in $ ${data?.amount}`
    : `${data?.stepsCompleted} steps completed of ${data?.steps?.length}`;
}

export function calculateGoalPercentage(data) {
  if (data?.goalType === "Financial") {
    const totalAmount = data?.amount;
    const savedAmount = data?.savedAmount;
    const percentage = (savedAmount / totalAmount) * 100;
    return percentage.toFixed(2);
  } else {
    const totalSteps = data?.steps?.length;
    const completedSteps = data?.stepsCompleted;
    const percentage = (completedSteps / totalSteps) * 100;
    return isNaN(percentage) ? "0.00" : percentage.toFixed(2);
  }
}

export function formatDateArray(data) {
  let formatedEvents = data;

  if (data.length) {
    formatedEvents = data.map((eventData) => {
      return {
        _id: eventData._id,
        title: eventData.title,
        start: moment(eventData.startDate).format("MMMM DD, YYYY hh:mm A"),
        end: moment(eventData.endDate).format("MMMM DD, YYYY hh:mm A"),
        createdBy: eventData.createdBy,
        ...(eventData?.createdBy === "Coach" && {
          arrangedBy: eventData?.coachId,
        }),
        ...(eventData.createdBy === "Staff" && {
          arrangedBy: eventData?.staffs[0],
        }),
      };
    });
  }
  return formatedEvents;
}
