import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import Loader from "../../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  getFinancialReport,
  getSmartPlan,
  viewOrDownloadClientFinancialReport,
} from "../../Redux/Actions";
import SmartPlan from "../SmartPlan/SmartPlan";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import DeleteSmartPlan from "../../components/Modal/DeleteSmartPlan";
import SmartPlanCard from "../../components/Card/SmartPlanCard";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import pdf from "../../Assets/pdf.png";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import TextModal from "../../components/Modal/TextModal";
import useHasPermission from "../../Utils/permissionUtils";

let Once = true;
const AllSmartPlan = () => {
  const hasPermissionToCreate = useHasPermission("Create Smart Plan");
  const hasPermissionToUpdate = useHasPermission("Modify Smart Plan");
  const hasPermissionToDelete = useHasPermission("Delete Smart Plan");
  const hasPermissionToAccess = useHasPermission(
    "Financial Plan (with coach authorization)"
  );
  const [loading, setLoading] = useState(false);
  const { data: smartPlanData, financialReport } = useSelector(
    (state) => state.smartPlan
  );

  const [screenDisplay, setScreenDisplay] = useState();
  const [updatedSmartPlan, setUpdatedSmartPlan] = useState(null);
  const [deleteSmartPlan, setDeleteSmartPlan] = useState({
    modal: false,
    id: null,
  });
  const [textModal, setTextModal] = useState(false);
  const dispatch = useDispatch();

  function handleEditSmartPlan() {
    if (hasPermissionToUpdate) {
      setUpdatedSmartPlan(() => smartPlanData[0]);
      setScreenDisplay("create");
    } else {
      setTextModal(true);
    }
  }

  function handleDeleteSmartPlan(id) {
    if (hasPermissionToDelete) {
      setDeleteSmartPlan({ id: id, modal: true });
    } else {
      setTextModal(true);
    }
  }

  const generatePdf = (data, option) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = data;

    // Append the container to the body (it will be removed after PDF generation)
    document.body.appendChild(tempContainer);
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(tempContainer, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.75);
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        if (option === "view") {
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            newWindow.focus();
          } else {
            alert("Please allow popups for this website");
          }
        } else {
          pdf.save("financialReport.pdf");
        }
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      })
      .finally(() => {
        document.body.removeChild(tempContainer);
      });
  };

  async function handleDownloadOrView(option) {
    if (hasPermissionToAccess) {
      setLoading(true);
      const url = `${smartPlanData[0].userId}/${smartPlanData[0]._id}/${option}`;
      try {
        const response = await viewOrDownloadClientFinancialReport(url);
        generatePdf(response.data, option);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      setTextModal(true);
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(smartPlanData)) {
        setLoading(true);
        dispatch(getSmartPlan()).then(() => {
          dispatch(getFinancialReport()).then(() => {
            setLoading(false);
          });
        });
      }
    }
  }, []);

  return (
    <>
      {deleteSmartPlan.modal && (
        <DeleteSmartPlan
          open={deleteSmartPlan.modal}
          handleModal={() => setDeleteSmartPlan({ modal: false, id: null })}
          data={deleteSmartPlan.id}
        />
      )}
      {textModal && (
        <TextModal
          open={textModal}
          isPermission={true}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      <DeleteSmartPlan />
      {loading ? (
        <Loader />
      ) : screenDisplay === "create" ? (
        <SmartPlan
          data={updatedSmartPlan}
          setScreenDisplay={() => {
            setUpdatedSmartPlan(() => {
              return null;
            });

            setScreenDisplay("create");
          }}
        />
      ) : (
        <>
          <HeaderData text="Smart Plans">
            {!smartPlanData?.length ? (
              <ButtonIcon
                onclick={() => {
                  if (hasPermissionToCreate) {
                    setScreenDisplay("create");
                  } else {
                    setTextModal(true);
                  }
                }}
                icon={<IoMdAdd />}
                text="Smart Plan"
              />
            ) : null}
          </HeaderData>

          {financialReport?.length > 0 ? (
            <div className=" bg-white rounded-lg w-[280px] mt-2">
              <div
                onClick={() => {
                  handleDownloadOrView("download");
                }}
                className="overflow-hidden w-full h-[180px] bg-[--gray-light] flex justify-center items-center cursor-pointer group"
              >
                <img
                  src={pdf}
                  className="w-[40%] h-[60%] transition-all ease-in-out duration-500 group-hover:scale-[1.6]"
                  alt="Handout File"
                />
              </div>
              <div className="px-3 pt-2 pb-1">
                <h5 className="satoshi-700">Financial Report</h5>
              </div>
              <div className="px-3 pt-1 flex justify-between items-center pb-2">
                <h5 className="text-gray-500 text-sm">
                  {moment(parseInt(financialReport[0]?.createdAt)).format(
                    "DD MMM, YYYY"
                  )}
                </h5>
                <button
                  onClick={() => {
                    handleDownloadOrView("view");
                  }}
                  className="py-[3px] px-4 bg-[--primary] satoshi-500 text-white border border-[--primary] rounded-md transition-all ease-in-out duration-500 hover:bg-white hover:text-[--primary]"
                >
                  View
                </button>
              </div>
            </div>
          ) : null}
          {smartPlanData.length ? (
            <CardWrapper extraClasses="!py-2">
              <div className="w-full flex justify-end items-center gap-4 mt-3">
                <ButtonSmall
                  text="Edit"
                  onClick={handleEditSmartPlan}
                  customClasses="!bg-green-800 !border-green-800 hover:!bg-white hover:!text-green-700"
                />

                <ButtonSmall
                  text="Delete"
                  onClick={() => {
                    handleDeleteSmartPlan(smartPlanData[0]._id);
                  }}
                  customClasses="!bg-red-700 !border-red-700 hover:!bg-white hover:!text-red-600"
                />
              </div>
              {smartPlanData?.map((selectedSmartPlan, index) => {
                return (
                  <CardWrapper key={index} extraClasses={"!items-stretch"}>
                    {selectedSmartPlan?.income && (
                      <SmartPlanCard headerText={"Personal Income"}>
                        <RowWrapper
                          title="Company Name "
                          value={selectedSmartPlan?.income?.companyName}
                        />
                        <RowWrapper
                          title="Income Amount"
                          value={`$ ${selectedSmartPlan?.income?.income}`}
                        />
                        {selectedSmartPlan?.income?.additionalIncome && (
                          <>
                            <h4 className="satoshi-700 my-2">
                              Additional Income
                            </h4>
                            <RowWrapper
                              title="Income Type "
                              value={
                                selectedSmartPlan?.income?.additionalIncome
                                  ?.type
                              }
                            />
                            <RowWrapper
                              title="Business Type "
                              value={
                                selectedSmartPlan?.income?.additionalIncome
                                  ?.typeOfBusiness
                              }
                            />

                            <RowWrapper
                              title="Industry "
                              value={
                                selectedSmartPlan?.income?.additionalIncome
                                  ?.industry
                              }
                            />
                            <RowWrapper
                              title="Monthly Income "
                              value={`$ ${selectedSmartPlan?.income?.additionalIncome?.monthlyIncome}`}
                            />
                          </>
                        )}
                      </SmartPlanCard>
                    )}
                    {selectedSmartPlan?.financeShared && (
                      <SmartPlanCard headerText={"Spouse Information"}>
                        <RowWrapper
                          title="Gender "
                          value={selectedSmartPlan?.spouse?.gender}
                        />
                        <RowWrapper
                          title="Retirement Age"
                          value={`${selectedSmartPlan?.spouse?.retirementAge} years`}
                        />

                        <RowWrapper
                          title="Birth Month "
                          value={selectedSmartPlan?.spouse?.dob?.month}
                        />
                        <RowWrapper
                          title="Birth Year "
                          value={selectedSmartPlan?.spouse?.dob?.year}
                        />
                      </SmartPlanCard>
                    )}

                    {selectedSmartPlan?.spouseIncome && (
                      <SmartPlanCard headerText={"Spouse Income"}>
                        <RowWrapper
                          title="Company Name "
                          value={selectedSmartPlan?.spouseIncome?.companyName}
                        />
                        <RowWrapper
                          title="Income Amount"
                          value={`$ ${selectedSmartPlan?.spouseIncome?.income}`}
                        />
                        {selectedSmartPlan?.spouseIncome?.additionalIncome && (
                          <>
                            <h4 className="satoshi-700 my-2">
                              Additional Income
                            </h4>
                            <RowWrapper
                              title="Income Type "
                              value={
                                selectedSmartPlan?.spouseIncome
                                  ?.additionalIncome?.type
                              }
                            />
                            <RowWrapper
                              title="Business Type "
                              value={
                                selectedSmartPlan?.spouseIncome
                                  ?.additionalIncome?.typeOfBusiness
                              }
                            />

                            <RowWrapper
                              title="Industry "
                              value={
                                selectedSmartPlan?.spouseIncome
                                  ?.additionalIncome?.industry
                              }
                            />
                            <RowWrapper
                              title="Monthly Income "
                              value={`$ ${selectedSmartPlan?.spouseIncome?.additionalIncome?.monthlyIncome}`}
                            />
                          </>
                        )}
                      </SmartPlanCard>
                    )}

                    {selectedSmartPlan?.dependentsInfp?.doHave ? (
                      <SmartPlanCard headerText={"Dependents Information"}>
                        {selectedSmartPlan?.dependentsInfp?.dependents?.map(
                          (item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={
                                    selectedSmartPlan?.dependentsInfp
                                      ?.dependents?.length > 1
                                      ? "w-full pb-1 border-b"
                                      : "w-full"
                                  }
                                >
                                  <RowWrapper title="Name" value={item?.name} />
                                  <RowWrapper
                                    title="Age"
                                    value={`${item?.age} years`}
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                        {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo
                          ?.length ? (
                          <>
                            <h4 className="satoshi-700 my-2">
                              Custodial Account
                            </h4>
                            {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo?.map(
                              (item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={
                                        selectedSmartPlan?.dependentsInfp
                                          ?.custodialAccountInfo.length > 1
                                          ? "w-full pb-1 border-b"
                                          : "w-full"
                                      }
                                    >
                                      <RowWrapper
                                        title="Institution Name"
                                        value={item?.Institution}
                                      />
                                      <RowWrapper
                                        title="Account Number"
                                        value={`$ ${item?.Amount}`}
                                      />
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : null}
                      </SmartPlanCard>
                    ) : null}

                    {selectedSmartPlan?.Home && (
                      <SmartPlanCard headerText={"Home Information"}>
                        <RowWrapper
                          title="Home Type"
                          value={selectedSmartPlan?.Home?.type}
                        />
                        {selectedSmartPlan?.Home?.type === "rent" && (
                          <RowWrapper
                            title="Rent"
                            value={`$ ${selectedSmartPlan?.Home?.rent}`}
                          />
                        )}

                        {selectedSmartPlan?.Home?.mortgageDetails && (
                          <>
                            <RowWrapper
                              title="Home Value"
                              value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.homeValue}`}
                            />

                            <RowWrapper
                              title="Loan Balance"
                              value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.loanBalance}`}
                            />
                            <RowWrapper
                              title="Monthly Payment"
                              value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.monthlyPayment}`}
                            />
                            <RowWrapper
                              title="Interest Rate"
                              value={
                                selectedSmartPlan?.Home?.mortgageDetails
                                  ?.interestRate
                              }
                            />
                          </>
                        )}
                      </SmartPlanCard>
                    )}

                    {selectedSmartPlan?.debts?.length ? (
                      <SmartPlanCard headerText={"Debt Information"}>
                        {selectedSmartPlan?.debts.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.debts.length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper
                                title="Debt Type"
                                value={item?.debtType}
                              />

                              <RowWrapper
                                title="Institution Name"
                                value={item?.institution}
                              />
                              <RowWrapper
                                title="Debt Balance"
                                value={`$ ${item?.balance}`}
                              />
                              <RowWrapper
                                title="Monthly Payment"
                                value={`$ ${item?.monthlyPayment}`}
                              />
                              <RowWrapper
                                title="Debt Rate"
                                value={item?.rate}
                              />
                            </div>
                          );
                        })}

                        <RowWrapper
                          title="Total Debt"
                          value={`$ ${selectedSmartPlan?.totalDebt}`}
                        />
                        <RowWrapper
                          title="Debt Standing "
                          value={selectedSmartPlan?.debtStanding}
                        />
                      </SmartPlanCard>
                    ) : null}

                    {selectedSmartPlan?.accounts.length ? (
                      <SmartPlanCard headerText={"Accounts Information"}>
                        {selectedSmartPlan?.accounts.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.accounts.length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper
                                title="Account Type"
                                value={item?.type}
                              />

                              <RowWrapper
                                title="Institution Name"
                                value={item?.institution}
                              />
                              <RowWrapper
                                title="Account Balance"
                                value={`$ ${item?.balance}`}
                              />
                            </div>
                          );
                        })}
                      </SmartPlanCard>
                    ) : null}

                    {selectedSmartPlan?.property.length ? (
                      <SmartPlanCard headerText={"Assets Information"}>
                        {selectedSmartPlan?.property.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.property.length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper
                                title="Asset Name"
                                value={item?.name}
                              />

                              <RowWrapper
                                title="Asset Value"
                                value={`$ ${item?.value}`}
                              />
                            </div>
                          );
                        })}
                      </SmartPlanCard>
                    ) : null}

                    <SmartPlanCard headerText={"Other Information"}>
                      <RowWrapper
                        title="Last Will "
                        value={
                          selectedSmartPlan?.questions?.lastWill ? "Yes" : "No"
                        }
                      />

                      <RowWrapper
                        title="Living Trust "
                        value={
                          selectedSmartPlan?.questions?.livingTrust
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Health Care Power Of Attorney "
                        value={
                          selectedSmartPlan?.questions
                            ?.healthCarePowerOfAttorney
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Financial Power Of Attorney "
                        value={
                          selectedSmartPlan?.questions?.financialPowerOfAttorney
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Documents Organized "
                        value={
                          selectedSmartPlan?.questions?.documentsOrganized
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Identity Theft Protection "
                        value={
                          selectedSmartPlan?.questions?.identityTheftProtection
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Debt Free "
                        value={
                          selectedSmartPlan?.questions?.onTrackDebtFree
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Budgeting And Debt-Payoff Tool "
                        value={
                          selectedSmartPlan?.questions
                            ?.budgetingAndDebtPayoffTool
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Prepared To Manage Finances "
                        value={
                          selectedSmartPlan?.questions?.preparedToManageFinances
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Interested In Education And Services "
                        value={
                          selectedSmartPlan?.questions
                            ?.interestedInEducationAndServices
                            ? "Yes"
                            : "No"
                        }
                      />

                      <RowWrapper
                        title="Reporting Rent Payments "
                        value={
                          selectedSmartPlan?.questions?.reportingRentPayments
                            ? "Yes"
                            : "No"
                        }
                      />
                    </SmartPlanCard>
                  </CardWrapper>
                );
              })}
            </CardWrapper>
          ) : (
            <p>You don't have any smart plan</p>
          )}
        </>
      )}
    </>
  );
};

export default AllSmartPlan;
